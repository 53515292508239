<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <b-card>
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search exercises"
            type="text"
          />
          <b-button
            variant="primary mr-1"
            @click="selectedExercise = 'new'; $bvModal.show('applicationModal')"
          >
            <feather-icon icon="PlusIcon" />
            Add Exercise
          </b-button>
          <b-button
            v-if="$route.params.wid"
            variant="outline-primary mr-1"
            @click="$router.replace(`/programs/${$route.params.id}/manage#Exercises`)"
          >
            Show All
          </b-button>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :sort-options="{
          enabled: true,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'title'"
            class="d-flex justify-content-between"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span
            v-else-if="props.column.field === 'actions'"
          >
            <b-button
              v-if="props.row.status === 'draft'"
              class="mr-50"
              size="sm"
              variant="outline-primary"
              @click="selectedExercise = props.row.originalIndex; $bvModal.show('applicationModal')"
            >Edit Form</b-button>
            <b-button
              v-else
              :to="{name: 'review-questions',
                    params: {pid: $route.params.id,
                             apid: props.row.id}}"
              class="mr-50"
              size="sm"
              variant="outline-primary"
            >
              <span>Preview</span>
            </b-button>
            <b-button
              :to="{name: 'sdp-exercise-students-responses',
                    params: {program_id: $route.params.id, assignment_id: props.row.id}}"
              class="mr-50"
              size="sm"
              variant="outline-primary"
            >
              <span>View Startups</span>
            </b-button>
            <b-button
              aria-label="Delete"
              class="mr-50"
              size="sm"
              variant="flat-danger"
              @click="selectedRow = props.row.originalIndex; selectedoperationid = rows[props.row.originalIndex].id;$bvModal.show('deleteModal');"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </span>
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
              style="text-transform: capitalize;"
            >
              {{ props.formattedRow[props.column.field] || 'Draft' }}
            </b-badge><br>
            <b-form-checkbox
              v-b-tooltip="props.row.status==='enabled' ? 'Submissions Enabled' : 'Submissions Disabled'"
              :checked="props.row.status==='enabled'"
              class="mt-50 custom-control-primary"
              name="check-button"
              switch
              @change="updateAssignmentStatus(props.row.status, props.row.id, `status`)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="props.column.field === 'review_status'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
              style="text-transform: capitalize;"
            >
              {{ props.formattedRow[props.column.field] || 'Draft' }}
            </b-badge><br>
            <b-form-checkbox
              v-b-tooltip="props.row.review_status==='enabled' ? 'Evaluation Enabled' : 'Evaluation Disabled'"
              :checked="props.row.review_status==='enabled'"
              class="mt-50 custom-control-primary"
              name="check-button"
              switch
              @change="updateAssignmentStatus(props.row.review_status, props.row.id, `review_status`)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </span>
          <span v-else-if="props.column.field === 'operation_title'">
            <b-badge
              class="mr-50"
              variant="light-primary"
            >{{ props.row.operation_type }}</b-badge> {{ props.formattedRow[props.column.field] }}

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="applicationModal"
        :ok-disabled="!exerciseTitle || !selectedOperation "
        :title="selectedExercise === 'new' ? 'Add Exercise' : 'Edit Exercise'"
        no-close-on-backdrop
        ok-title="Save"
        size="lg"
        @ok="addExercise"
      >
        <b-row v-if="selectedExercise === 'new'">
          <b-col>
            <b-form-group
              label="Title"
              label-size="sm"
            >
              <b-form-input
                v-model="exerciseTitle"
                placeholder="Exercise Title"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Operation"
              label-size="sm"
            >
              <treeselect
                v-model="selectedOperation"
                :default-expand-level="1"
                :normalizer="normalizer"
                :options="operationOptions"
                placeholder="Select Phase or Activities"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <form-builder
          v-if="selectedExercise === 'new'"
          v-model="applicationForm"
        />
        <form-builder
          v-else-if="selectedExercise !== null"
          v-model="rows[selectedRow].programs_assignmentquestionstables"
          :inherited-sections="rows[selectedRow].programs_assignmentquestionstables"
        />
      </b-modal>
      <b-modal
        id="recommendationModal"
        ok-only
        ok-title="Update"
        title="Recommendations"
        @ok="updateStatus"
      >
        <div v-if="startupRows[selectedRow]">
          <b-form-group
            label="Recommendations"
          >
            <b-form-input
              v-model="startupRows[selectedRow].programs_assignmentscorerecords[0].recommendation"
              placeholder="Your recommendation for startup"
            />
          </b-form-group>
          <b-form-group
            label="Verdict"
          >
            <v-select
              v-model="startupRows[selectedRow].programs_assignmentscorerecords[0].verdict"
              :options="['Excellent','Good','Satisfactory','Unsatisfactory','Incomplete']"
              placeholder="Select from list"
            />
          </b-form-group>
        </div>
      </b-modal>
      <b-modal
        id="deleteModal"
        button-size="sm"
        centered
        no-close-on-backdrop
        ok-title="Yes, Delete"
        ok-variant="danger"
        size="sm"
        title="Confirm Delete"
        @ok="deleteExercise()"
      >
        <h6>Are you sure you want to delete this Exercise?</h6>
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    FormBuilder,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    Treeselect,
    // BNavItemDropdown,
    // BNavbarNav,
  },
  data() {
    return {
      mutationLoading: false,
      searchTerm: '',
      startupSearchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      selectedExercise: null,
      pageLength: 10,
      startupPageLength: 10,
      open: false,
      isFiltered: false,
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
        },
        {
          open: false,
          selected: null,
          title: 'Mentors',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Score',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Verdict',
        },
        {
          open: false,
          selected: 50,
          title: 'Weightage of Verdict :',
        },
        {
          open: false,
          selected: null,
          title: 'Is Assigned',
        },
      ],
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Exercise',
          field: 'title',
        },
        {
          label: 'Operation',
          field: 'operation_title',
        },
        {
          label: 'Submission',
          field: 'status',
          tdClass: 'text-center',
        },
        {
          label: 'Evaluation',
          field: 'review_status',
          tdClass: 'text-center',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
      startupColumns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field(row) {
            if (row.programs_assignmentscorerecords.length === 0) {
              return '-'
            }
            return row.programs_assignmentscorerecords[0].recommendation
          },
        },
        {
          label: 'Verdict',
          field(row) {
            if (row.programs_assignmentscorerecords.length === 0) {
              return '-'
            }
            return row.programs_assignmentscorerecords[0].verdict
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      startupRows: [],
      filterMentors: [],
      selectedExerciseId: null,
      exerciseTitle: null,
      selectedOperation: null,
      operationOptions: [],
      selectedoperationid: 0,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.operations_type ? `[${node.operations_type.charAt(0)}] ${node.title}` : node.title,
        children: node.programs_operationstables,
      }
    },
    addExercise() {
      this.mutationLoading = true
      if (this.selectedExercise !== 'new' && this.selectedExercise !== null) {
        this.updateApplication()
        return
      }
      this.$apollo.mutate(
        {
          mutation: gql`mutation($data: [programs_assignmentquestionstable_insert_input!]!) {
                        insert_programs_assignmenttable_one(object: {title: "${this.exerciseTitle}", status: "draft", review_status: "draft", operations_id: ${this.selectedOperation}, program_id: ${this.$route.params.id}, programs_assignmentquestionstables: {data: $data}}) {
                          id
                        }
                      }`,
          variables: {
            data: this.applicationForm,
          },
          update: (store, { data: { insert_programs_assignmenttable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_programs_assignmenttable_one.id ? 'Exercise added successfully' : 'Failed to create exercise',
                icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        },
      )
      this.exerciseTitle = null
      this.selectedOperation = null
      this.applicationForm = null
    },
    deleteExercise() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${this.selectedoperationid} }, _set: {is_deleted: "true"}) {
            id
          }
        }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Exercise Deleted Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateApplication() {
      const payload = this.rows[this.selectedExercise]

      if (payload.programs_assignmentquestionstables.length) {
        payload.programs_assignmentquestionstables = {
          data: payload.programs_assignmentquestionstables,
          on_conflict: {
            constraint: 'programs_assignmentquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
        delete payload.operation_title
      } else {
        delete payload.programs_assignmentquestionstables
      }
      // console.log(payload)
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_assignmenttable_insert_input!) {
            insert_programs_assignmenttable_one(object: $object, on_conflict: {constraint: programs_assignmenttable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: (store, { data: { insert_programs_assignmenttable_one } }) => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_assignmenttable_one.id ? 'Exercise updated successfully' : 'Failed to update exercise',
              icon: insert_programs_assignmenttable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_assignmenttable_one.id ? 'success' : 'danger',
            },
          })
        },
      }))
    },
    updateAssignmentStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_assignmenttable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`query ProgramsExercises {
          programs_operationstable(order_by: {id: desc}, where: {program_id: {_eq: ${this.$route.params.id}} ${this.$route.params.wid ? `, id: {_eq: ${this.$route.params.wid}}` : ''}}) {
            title
            id
            programs_assignmenttables {
              id
              status
              review_status
              title
              programs_assignmentquestionstables {
                id
                question
                section
                options
                input_type
                is_required
              }
            }
            operations_type
          }
        }`
      },
      update: data => data.programs_operationstable.map(e => (e.programs_assignmenttables.length ? { ...e.programs_assignmenttables[0], operation_title: e.title } : null)).filter(e => e).sort((a, b) => b.id - a.id),
    },
    operationOptions: {
      query() {
        return gql`{
          programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "SubPhase"}}) {
                id
                title
                programs_operationstables {
                    id
                    title
                    operations_type
                }
              }
            }`
      },
      update(data) {
        return data.programs_operationstable
      },
    },
  },
}
</script>

<style>
.dropdown-toggle::after {
  display:none;
}
.filters-box{
  border-top: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
}
.fl-col-1{
  width:fit-content;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}
.fl-col-2{
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-left: solid 1px #d3d3d3;
  border-right: solid 1px #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: auto;
  overflow-y: visible; */
}
.fl-col-2::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.fl-col-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.fl-col-2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.filter-holder{
  display: flex;
}
.fl-col-3{
  width:fit-content;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
</style>
